li.thread-comment-wrapper {
  border-radius: 5px;
  cursor       : pointer;
  padding      : 0;

  .comment-editor-wrapper {
    display   : none;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
  }

  &:hover,
  &.active {
    background-color: #484d52;
  }

  &.active {
    cursor: default;

    .comment-editor-wrapper {
      display: block;
    }
  }
}