.zoom-action-wrapper {
  display       : flex;
  flex-direction: row;

  .current-zoom {
    color: #a9a9a9;
  }

  .btn-zoom {
    color     : #a9a9a9;
    box-shadow: none;
  }
}