@import '../../../../scss/theme';

.panel-versions-wrapper {
  position: fixed;
  top: 72px;
  left: 10px;
  bottom: 10px;
  border-radius: 10px;
  background-color: $black;
  z-index: 1100;
  font-size: 12px;
  width: 0px;
  overflow: hidden;
  padding: 15px;
  transition: width 0.15s ease-in-out;

  &.collapsed {
    width: 290px;

    .collapse-panel {
      border-left: 1px solid #4b5054;

      // &:before {
      //   display: flex;
      // }
    }
  }

  .content {
    width: 230px;
    height: 100%;
  }

  .collapse-panel {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: none;
    cursor: pointer;
    // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    //   border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    // &:before {
    //   content: ' ';
    //   display: none;
    //   position: absolute;
    //   z-index: -1;
    //   top: 0px;
    //   left: 2px;
    //   right: 0px;
    //   bottom: 0px;
    //   border-left: 1px solid #4b5054;
    // }

    // &:hover {
    //   background-color: $blue;
    // }

    .feather {
      color: lightgrey;
    }
  }

  ul {
    li {
      color: #fff;
      margin-bottom: 10px;
      cursor: pointer;

      &.active {
        .preview-version-file {
          border: 1px solid $blue;

          &::after {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            content: '';
            background: rgba(0, 174, 239, 0.25);
          }

          .toolbox-action {
            display: flex;
          }
        }
      }

      .preview-version-file {
        position: relative;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.04);
        background-position: center;
        background-size: cover;
        height: 125px;
        margin-bottom: 10px;

        .badge {
          position: absolute;
          right: 6px;
          top: 6px;
          font-size: 10px;

          .feather {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
        }

        .toolbox-action {
          flex-direction: row;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 6px;
          background-color: rgba(0, 0, 0, 0.8);
          display: none;
          align-items: center;
          justify-content: center;
          z-index: 1020;

          .btn {
            .feather {
              width: 14px;
              height: 14px;
              margin-right: 6px;
            }
          }

          .btn-danger:disabled {
            opacity: 0.5;
          }
        }
      }

      .time {
        color: #a9a9a9;
      }
    }
  }
}
