.page-loader-file {
  position        : fixed;
  top             : 0;
  left            : 0;
  right           : 0;
  bottom          : 0;
  visibility      : hidden;
  opacity         : 0;
  z-index         : 50;
  display         : none;
  background-color: #484d52;

  &.active {
    visibility: visible;
    opacity   : 1;
    display   : block;
  }

  .lds-ripple {
    display  : inline-block;
    position : relative;
    width    : 64px;
    height   : 64px;
    position : absolute;
    left     : 50%;
    top      : 50%;
    transform: translate(-50%, -50%)
  }

  .lds-ripple div {
    position     : absolute;
    border       : 4px solid #fff;
    opacity      : 1;
    border-radius: 50%;
    animation    : lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top    : 28px;
      left   : 28px;
      width  : 0;
      height : 0;
      opacity: 1;
    }

    100% {
      top    : -1px;
      left   : -1px;
      width  : 58px;
      height : 58px;
      opacity: 0;
    }
  }
}