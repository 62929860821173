@import "../../../../scss/theme";

.comment-item {
  &.comment-hide {
    display: none;
  }

  .mentioned-user {
    background-color: $darkblue;
    padding         : 1px 5px;
    border-radius   : 15px;
  }

  .comment-wrapp {
    display       : flex;
    flex-direction: row;
    color         : rgba(255, 255, 255, 0.8);

    .avatar-wrapper-comment-annotaion {

      .user-avatar-initial,
      .user-avatar-cover {
        z-index : 10;
        position: relative;
      }
    }

    .btn-resolve {
      padding    : 0;
      font-size  : 12px;
      color      : rgba(255, 255, 255, 0.64);
      opacity    : 0;
      visibility : hidden;
      display    : flex;
      align-items: center;

      .circle-icon {
        width       : 14px;
        height      : 14px;
        margin-right: 5px;
        fill        : rgba(255, 255, 255, 0.64);

        circle {
          stroke: rgba(255, 255, 255, 0.64);
          fill  : rgba(255, 255, 255, 0.64);
        }
      }

      &:hover {
        color: $blue;

        .circle-icon {
          fill: $blue;

          circle {
            stroke: $blue;
            fill  : $blue;
          }
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    .btn-more {
      padding   : 0;
      color     : rgba(255, 255, 255, 0.64);
      width     : 20px;
      height    : 20px;
      transform : translate(0, -25%);
      opacity   : 0;
      visibility: hidden;

      &:hover {
        color: $darkblue;
      }

      .more-icon {
        width : 20px;
        height: 20px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .comment-content {
      padding-bottom: 15px;
    }

    .text-date {
      color: rgba(255, 255, 255, 0.32);
    }

    .avatar-wrapper {
      position: relative;

      &::after {
        position   : absolute;
        top        : 0;
        bottom     : 0;
        left       : 50%;
        content    : " ";
        border-left: 1px solid #c3c3c3;
      }
    }

    .menu-comment {
      background   : #fff;
      padding      : 5px 10px;
      top          : 24px;
      left         : -38px;
      position     : absolute;
      z-index      : 10;
      border-radius: 5px;

      ul {
        margin: 0;

        li {
          margin: 0
        }
      }

      .btn {
        padding    : 0;
        font-size  : 12px;
        color      : #4d5256;
        display    : flex;
        font-weight: bold;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          color: $darkblue;
        }
      }

      .btn-delete {
        &:hover {
          color: $red;
        }
      }
    }

    .confirm-delete {
      .info-delete {
        font-size: 11px;
        color    : rgba(255, 255, 255, 0.64);
      }
    }
  }

  &:hover {
    .btn-resolve {
      opacity   : 1;
      visibility: visible;
    }

    .btn-more {
      opacity   : 1;
      visibility: visible;
    }
  }

  &:last-child,
  &.comment-parent {
    .avatar-wrapper {
      &::after {
        border-left: none;
      }
    }
  }
}