@import "../../../scss/theme";

.menu-user {
  position      : absolute;
  top           : 50px;
  right         : 0;
  display       : flex;
  flex-direction: column;
  z-index       : 15;
  padding       : 20px;
  box-shadow    : 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.16);
  background    : #fff;
  border-radius : 10px;
  width         : 360px;
}

.menu-user .btn {
  font-size  : 16px;
  color      : #4d5256;
  font-weight: bold;
}

.menu-user a:hover {
  color          : $blue;
  text-decoration: none;
}

.menu-user-fullname {
  font-size: 16px;
}

.menu-user-desc {
  display       : flex;
  flex-direction: column;
  line-height   : 14px;
}

.menu-user-desc span {
  font-size: 12px;
}