.backdrop-wrapper {
  position        : fixed;
  top             : 0;
  left            : 0;
  width           : 100vw;
  height          : 100vh;
  background-color: #000;
  transition      : opacity 0.15s linear;
  visibility      : hidden;
  opacity         : 0;
  z-index         : -1;

  &.active {
    visibility: visible;
    opacity   : 0.5;
    z-index   : 50;
  }
}