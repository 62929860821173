@import '../../../../scss/theme';

.my-spinner {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .spinner-loader {
    -webkit-animation: app-loader-rotator 1.8s linear infinite;
    animation: app-loader-rotator 1.8s linear infinite;
    transform-origin: center;
  }

  .spinner-loader .path {
    transform-origin: center;
    stroke: $blue;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    -webkit-animation: app-loader-dash 1.8s ease-in-out infinite;
    animation: app-loader-dash 1.8s ease-in-out infinite;
  }
}

@-webkit-keyframes app-loader-rotator {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

@keyframes app-loader-rotator {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

@-webkit-keyframes app-loader-dash {
  0% {
    stroke-dashoffset: 100;
    transform: rotate(-450deg);
  }

  50% {
    stroke-dashoffset: 50;
    transform: rotate(-360deg);
  }

  100% {
    stroke-dashoffset: 100;
    transform: rotate(0);
  }
}

@keyframes app-loader-dash {
  0% {
    stroke-dashoffset: 100;
    transform: rotate(-450deg);
  }

  50% {
    stroke-dashoffset: 50;
    transform: rotate(-360deg);
  }

  100% {
    stroke-dashoffset: 100;
    transform: rotate(0);
  }
}
