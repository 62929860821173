.textarea-wrapper {
  textarea {
    min-height: 32px;
    line-height: 20px;
  }

  .input-validation-msg {
    bottom: unset;

    span {
      padding: 5px 10px;
    }
  }
}
