@import '../../../scss/theme';

.membership-status {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: $black;
  border-radius: 10px;
  padding: 8px 10px 10px 10px;
  position: relative;
}
