.page-verify-user-email {
  // background-color: $auth_background;
  background: linear-gradient(45deg, #3b2789 0%, #0d023b 100%);
  position: relative;
}

.verify-email-wrapper {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  .logo {
    padding: 21px 0 0 0;
  }

  .orbs1 {
    position: absolute;
    top: 189px;
    left: 110px;

    img {
      width: 72px;
      height: 72px;
    }
  }

  .orbs2 {
    position: absolute;
    top: 268px;
    right: 165px;

    img {
      width: 28px;
      height: 28px;
    }
  }

  h1 {
    margin-bottom: 15px;
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
    text-align: center;
  }

  p {
    margin-bottom: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Messina Sans';
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .btn-link {
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
  }

  .form-verify-email {
    padding: 30px 0;
    width: 380px;
    max-width: 358px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);

    label {
      color: rgba(255, 255, 255, 0.75);
      font-family: 'Messina Sans';
      font-size: 12px;
      line-height: 24px;
    }

    .textbox-form-container {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 0;
      background-color: transparent;

      input {
        color: #ffffff;
        font-family: 'Messina Sans';
        font-size: 14px;
        line-height: 24px;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0 0 8px 0;
      }
    }

    .btn-send-email {
      color: #ffffff;
      font-family: 'Messina Sans';
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: normal;
      height: 50px;
      width: 163px;
      margin-top: 40px;
    }
  }

  .success-submit {
    padding: 30px 0;
    width: 380px;
    max-width: 358px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);
    text-align: center;

    h1 {
      margin: 30px 0 15px 0;
    }

    p {
      margin-bottom: 40px;
      width: 359px;
    }

    .btn-send-email {
      margin-bottom: 15px;
      height: 50px;
      width: 163px;
      border-radius: 25px;
      background-color: #60269e;
      font-weight: normal;
    }
  }
}
