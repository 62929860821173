@import "../../../scss/theme";

.container-working-hours .progress-container>.progress {
  background-color: #c9c9c9;
}

.container-working-hours {
  .dot-primary {
    background-color: $primary_workinghours !important;
  }
  
  .text-primary {
    color: $primary_workinghours !important;
  }

  .bg-primary {
    background-color: $primary_workinghours !important;
  }
}