.loader-component-loader {
  position  : absolute;
  top       : 0;
  left      : 0;
  right     : 0;
  bottom    : 0;
  background: #fff;
  z-index   : 1;

  .spinner-wrapp {
    background: transparent;

    .lds-ring div {
      border      : 4px solid #00adee;
      border-color: #00adee transparent transparent transparent;
    }
  }

  .api-loader-logo-wrapp {
    position  : absolute;
    top       : 0;
    left      : 0;
    right     : 0;
    bottom    : 0;
    background: #fff;

    .api-loader-shadow .api-loader-logo {
      width : 50px;
      height: 50px;

      svg.ngine-art-logo {
        width : 50px;
        height: 50px;
      }
    }
  }
}