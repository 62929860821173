@import '../../../../scss/theme';

.panel-comment-wrapper {
  position: fixed;
  top: 72px;
  right: 10px;
  bottom: 10px;
  border-radius: 10px;
  background-color: $black;
  z-index: 1100;
  font-size: 12px;
  width: 0px;
  overflow: hidden;
  transition: width 0.15s ease-in-out;
  padding: 45px;

  .nav-panel {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 8px;
    border-left: 1px solid #4b5054;

    .btn {
      margin-bottom: 10px;
      padding: 6px;
      color: #ccc;
      position: relative;

      &.active {
        background-color: $darkblue;
      }

      &:hover {
        background-color: $darkblue;
      }

      .badge {
        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
  }

  &.collapsed {
    width: 440px;
    padding: 0px;

    .collapse-panel {
      border-right: 1px solid #4b5054;

      // &::before {
      //   display: flex;
      // }
    }
  }

  .content {
    width: 350px;
    margin-left: 30px;
    height: 100%;
  }

  .collapse-panel {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    cursor: pointer;
    // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    //   border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    // &:before {
    //   content: ' ';
    //   position: absolute;
    //   z-index: -1;
    //   display: none;
    //   top: 0px;
    //   left: 0px;
    //   right: 2px;
    //   bottom: 0px;
    //   border-right: 1px solid #4b5054;
    // }

    // &:hover {
    //   background-color: $blue;
    // }

    .feather {
      color: lightgrey;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .panel-comment-list {
    overflow: auto;
    height: 100%;
    padding: 15px;
  }

  .comment-container-wrapper {
    max-height: unset;
  }
}
