.form-signin {
  width    : 100%;
  max-width: 330px;
  padding  : 100px 15px;
  margin   : auto;
}

.page-signin,
.page-adminsignin {
  .Toastify__toast-container--top-right {
    top  : 15px;
    right: 10px;
  }
}