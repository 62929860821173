::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c3c3c3 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3c3c3 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #c3c3c3 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #c3c3c3 !important;
}