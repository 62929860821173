.feather {
  width : 18px;
  height: 18px;
}

.feather-14 {
  width : 14px;
  height: 14px;
}

.feather-16 {
  width : 16px;
  height: 16px;
}

.feather-20 {
  width : 20px;
  height: 20px;
}

.feather-22 {
  width : 22px;
  height: 22px;
}

.feather-24 {
  width : 24px;
  height: 24px;
}

.feather-50 {
  width : 50px;
  height: 50px;
}