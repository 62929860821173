@import '../../../../scss/theme';

.toolbox-title {
  position: fixed;
  top: 65px;
  left: 50%;
  transform: translate(-50%, 0);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: row;
  // max-width: 200px;
  // margin: 0 auto 8px auto;
  z-index: 1050;
  background-color: $black;
  color: #a9a9a9;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #5d5d5d;
}

.toolbox-canvas-wrapper {
  position: fixed;
  // bottom: 20px;
  top: 100px;
  left: 50%;
  z-index: 1050;
  transform: translate(-50%, 0);
  background-color: $black;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.16);

  span.separator {
    display: block;
    width: 1px;
    height: 25px;
    background-color: #a9a9a9;
  }

  .btn-toolbox {
    color: #a9a9a9;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 40px;
    padding: 0;
    margin: 0 1px;
    box-shadow: none;

    &:hover:not(:disabled),
    &.active:not(:disabled) {
      color: #fff;
      background-color: $darkblue;
    }
  }

  .btn-toolbox-palette {
    &:hover,
    &.active {
      color: transparent;
      background-color: transparent;
    }
  }
}
