@import '../../scss/theme';

.quick-search-wrapp {
  position: relative;
  z-index: 1050;

  .spinner-wrapp {
    right: -50px;
    transform: unset;
    background: unset;
    padding: 0;
    top: 0;
    left: unset;
    width: auto;
    height: auto;

    .lds-ring {
      width: 50px;
      height: 50px;

      div {
        width: 24px;
        height: 24px;
        border-width: 2px;
        border-top-color: $darkblue;
      }
    }
  }

  .quick-search-input-wrapp {
    background: #e0dede;
    border-radius: 5px;
    height: 43px;
    width: 300px;
    position: relative;
    z-index: 1050;
    transition: all 300ms ease-in-out;

    form {
      height: 100%;
      display: block;
    }

    &.active {
      background: #fff;
      width: 300px;

      input {
        visibility: visible;
        opacity: 1;
      }

      .btn-icon-search {
        left: unset;
      }
    }

    input {
      border: none;
      background: transparent;
      padding: 8px 30px 8px 10px;
      width: 100%;
      height: 100%;
      font-size: 13px;

      &:hover,
      &:focus {
        border: none;
        outline: none;
      }
    }

    .btn-icon-search {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 10px 0;
      display: flex;
      align-items: center;
      color: #ccc;
    }
  }

  .spinner {
    position: absolute;
    right: -40px;
    top: 9px;
    z-index: 1050;
  }

  @media only screen and (max-width: 991px) {
    .quick-search-input-wrapp {
      width: 45px;

      input {
        visibility: hidden;
        opacity: 0;
      }

      .btn-icon-search {
        width: 45px;
      }
    }
  }
}
