.broken-link-wrapper {
  position        : fixed;
  left            : 0;
  right           : 0;
  top             : 0;
  bottom          : 0;
  background-color: #343a3f;
  z-index         : 1280;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -90%);
  }

  .title {
    font-size  : 72px;
    font-weight: 700;
  }
}