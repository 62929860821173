.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 1080;
  display: none;
  background-color: #484d52;

  &.active {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}
