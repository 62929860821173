@import "../../../scss/theme";
// notification or widget theme
$notifHighlightYellow: rgba(236, 179, 107, 0.1);
$notifHighlightCyan: rgba(107, 218, 236, 0.1);

.popover-notification-toolbar {
  position        : absolute;
  top             : 50px;
  right           : 0;
  border-radius   : 10px;
  background-color: #fff;
  z-index         : 15;
  box-shadow      : 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.16);
  width           : 490px;
  overflow        : hidden;
}

.notification-link-item {
  color          : inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color          : inherit;
  }
}

.label-notification {
  display        : flex;
  justify-content: flex-end;
  font-weight    : 700;
  font-size      : 16px;
}

.btn-notification-separator {
  padding        : 0;
  margin         : 0;
  color          : #a9a9a9;
  font-size      : 12px;
  line-height    : 14px;
  font-weight    : normal;
  display        : flex;
  justify-content: center;
  margin         : 0 5px;
}

.notification-wrapper {
  height    : calc(100% - 21px - 24px);
  overflow-y: auto;
  overflow-x: hidden;

  .btn-load-more {
    margin-top: 10px;
    width: 100%;
    color: #000000;
    background-color: #efefef;
    &:hover {
      color: #000000;
      background-color: #e0e0e0;
    }
  }
}

.btn-notification {
  padding    : 0;
  margin     : 0;
  color      : #a9a9a9;
  font-size  : 12px;
  line-height: 14px;
  font-weight: normal;
  width      : auto;
  height     : auto;
}

.btn-notification:hover {
  color          : $blue;
  text-decoration: none;
}

.btn-back-notification:hover {
  color: $blue;
}

.notification-main {
  margin-right: 24px;
  width: 100%;

  .latest-notification {
    width: 100%;
    background-color: #f1f1f1;
    // color: #ffffff;
    border: 1.5px dashed #f1f1f1;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 3px;
    &:hover {
      background-color: #dddddd;
    }
  }

  .notification-content {
    .text-ready-to-go {
      color: $blue;
    }

    .text-queue {
      color: $orange;
    }

    .text-draft {
      color: $dark;
    }

    .text-in-progress {
      color: $blue;
    }

    .text-in-review {
      color: $blue;
    }

    .text-on-hold {
      color: $orange;
    }

    .text-approved {
      color: $blue;
    }

    .text-cancelled {
      color: $red;
    }
  }
}

.notification-action {
  position: absolute;
  top     : 0;
  right   : 0;
  bottom  : 0;
  width   : 24px;
}

.notification-item {
  border-radius: 10px;
  padding      : 10px;
  font-size    : 12px;
  line-height  : 16px;

  &.highlight-yellow {
    background-color: $notifHighlightYellow;
  }

  &.highlight-cyan {
    background-color: $notifHighlightCyan;
  }
}

.btn-read-notif .feather {
  stroke-width: 0;
  fill        : $blue;
}

.btn-unread-notif .feather {
  stroke-width: 0;
  fill        : #a9a9a9;
}

.btn-unread-notif,
.btn-remove-notif {
  visibility: hidden;
  opacity   : 0;
}

.notification-item:hover {
  background-color: #f1f1f1 !important;
}

.notification-item:hover .btn-unread-notif,
.notification-item:hover .btn-remove-notif {
  visibility: visible;
  opacity   : 1;
}