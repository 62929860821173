.canvas-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;

  &.cursor-cell {
    canvas {
      cursor: cell;
    }
  }

  &.cursor-move {
    canvas {
      cursor: move;
    }
  }

  &.cursor-disable {
    canvas {
      cursor: not-allowed;
    }
  }

  &.default-default {
    canvas {
      cursor: pointer;
    }
  }
}
