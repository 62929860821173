.header-wrapper {
  padding: 16px 16px 0 0;
}

.header-toolbar {
  padding         : 16px;
  border-radius   : 10px;
  height          : 80px;
  background-color: #efefef;
}

.navbar-dark .navbar-toggler {
  border: none;
}