.dashboard-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.dashboard {
  width: calc(100% - 335px);
  height: 100%;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}

.dashboard.active .main,
.dashboard.active .header-wrapper {
  padding-left: 20px;
}

.dashboard.active {
  width: 100%;
}

.main {
  padding: 0;
  height: calc(100% - 96px);
  display: flex;
  // overflow: hidden;
}

.welcomeTourGuide {
  margin: 0px auto;
  width: 0px;
  height: 0px;
  padding-top: 17%;
  z-index: 0;
}

.tour-red {
  color: red;
}

.__floater.__floater__open {
  z-index: 999999 !important;
}

@media only screen and (max-width: 991px) {
  .dashboard {
    left: 0 !important;
    width: 100% !important;
  }
}
