.progress-read-file {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  z-index: 1080;
  color: #ccc;

  .progress {
    height: 3px;
    border-radius: 0px;
    box-shadow: 0px 0px 10px 2px #8651b9;

    .progress-bar {
      border-radius: 0px;
    }
  }
}
