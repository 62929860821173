.loader-downloading {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .my-spinner {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
