@import "../theme";

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;

  &.dot-darkprimary {
    background-color: $darkblue;
  }

  &.dot-primary {
    background-color: $blue;
  }

  &.dot-secondary {
    background-color: #c3c3c3;
  }

  &.dot-danger {
    background-color: $red;
  }
}

.dot-12 {
  width: 12px;
  height: 12px;
}
.dot-14 {
  width: 14px;
  height: 14px;
}
.dot-16 {
  width: 16px;
  height: 16px;
}
.dot-24 {
  width: 24px;
  height: 24px;
}
