@import '../../scss/theme';

.announcement-wrapper {
  width: 100%;
  background: $info_notification;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  transition: all 0.3s ease-in-out;

  .announcement-content {
    position: relative;
    .close-btn {
      position: absolute;
      z-index: 99999;
      top: 0;
      right: 0;
      margin-top: 10px;
      margin-right: 10px;
      border: none;
      background: none;
    }
  }

  .flash-notification {
    padding: 10px;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
      color: #ffffff;
    }

    a {
      color: #ffffff;
      text-decoration: underline;
    }

    &[data-flash-status='warning'] {
      background-color: $orange;
    }
    &[data-flash-status='danger'] {
      background-color: $red;
    }
  }
}
