@import "../theme";

$blueTransparent: rgba(0, 174, 239, 0.45);
$font-family    : 'Open Sans',
sans-serif !default;

.input-group-text {
  background: transparent;
  border    : none;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin: 0 !important;
}

.input-group {
  border       : 1px solid #ced4da;
  border-radius: 5px;

  .form-control {
    border    : none;
    padding   : 0;
    background: transparent;
  }

  .react-datepicker-wrapper {
    width: 1%;
    flex : 1 1 auto;
  }

  .react-datepicker-popper {
    right    : 0;
    top      : 40px !important;
    transform: none !important;

    .react-datepicker {
      width        : 100%;
      border-radius: 5px;
      font-weight  : 600;
      font-family  : $font-family;
      color        : #4d5256;
      border       : 1px solid #cfcfcf;

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__month-container {
        width  : 100%;
        padding: 15px;
        color  : #4d5256;

        .react-datepicker__header {
          background-color: transparent;

          .header-date-picker-custom {
            .header-title-current-month {
              font-size: 14px;
            }

            .btn-icon {
              color: #b7b7b7;

              .feather {
                width : 24px;
                height: 24px;
              }
            }
          }

          .react-datepicker__day-names {
            display        : flex;
            justify-content: center;
          }

          .react-datepicker__day-name {
            color          : #4d5256;
            font-weight    : 700;
            display        : flex;
            flex-grow      : 1;
            justify-content: center;
          }
        }

        .react-datepicker__month {
          margin: 0;

          .react-datepicker__week {
            display        : flex;
            justify-content: center;

            .react-datepicker__day {
              color          : #4d5256;
              display        : flex;
              flex-grow      : 1;
              justify-content: center;

              span {
                font-weight: 600;
                font-family: $font-family;
              }

              &:hover {
                color: #4d5256;

                span {
                  color: #4d5256;
                }
              }
            }

            .react-datepicker__day--disabled {
              span {
                color: #ced4da;
              }
            }

            .react-datepicker__day--weekend {
              span {
                color: $red;
              }
            }

            .react-datepicker__day--in-selecting-range {
              &:hover {
                color: #fff;

                span {
                  color: #fff;
                }
              }
            }

            .react-datepicker__day--in-range {
              background-color: $blueTransparent;
              color           : #fff;

              span {
                justify-content: center;
                align-items    : center;
                text-align     : center;
              }

              &:hover {
                background-color: $blueTransparent;
                color           : #fff;

                span {
                  color: #fff;
                }
              }
            }

            .react-datepicker__day--selected {
              color           : #fff;
              background-color: $blue;

              span {
                justify-content: center;
                align-items    : center;
                text-align     : center;
              }

              &:hover {
                color           : #fff;
                background-color: $blue;

                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.textbox-form-container {
  border       : 1px solid #ced4da;
  border-radius: 5px;
  position     : relative;

  &.textbox-form-container-disabled {
    background-color: #e9ecef;
    opacity         : 1;
  }

  input,
  textarea {
    border: none;
  }

  .input-validation-msg {
    position        : absolute;
    top             : 3px;
    right           : 3px;
    bottom          : 3px;
    background-color: $red;
    color           : #fff;
    border-radius   : 5px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    visibility      : hidden;
    opacity         : 0;
    transition      : opacity 350ms ease-in-out;

    span {
      display        : flex;
      justify-content: center;
      align-items    : center;
      padding        : 10px;
      font-size      : 13px;
    }

    &.show {
      opacity   : 1;
      visibility: visible;
    }
  }
}