html,
body,
#root,
.App-Design-Desk {
  height  : 100%;
}

/** Scroll browser, webkit only!!!
-------------------------------------------------------*/
@supports (-webkit-appearance: none) {
  /*
    hack for webkit target
    link                  : http://browserhacks.com/#hack-8a0d11a6b2e3d172711eed35cb7237b9
    also targets edge but ::-webkit-scrollbar doesn't apply
  */
  body::-webkit-scrollbar,
  body *::-webkit-scrollbar {
    width : 8px;
    height: 8px;
  }

  body::-webkit-scrollbar-thumb,
  body *::-webkit-scrollbar-thumb {
    background-color: #353a40;
    border          : 0px solid transparent;
    border-radius   : 5px;
  }

  body::-webkit-scrollbar-track,
  body *::-webkit-scrollbar-track {
    background-color: #c8c8c8;
    border-radius   : 5px;
  }

  body::-webkit-scrollbar-corner,
  body *::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .kit-scrollbar-small::-webkit-scrollbar {
    width : 4px;
    height: 4px;
  }

  .kit-scrollbar-autohide {
    -webkit-background-clip: text;
    background-color       : transparent;
    border-color           : transparent;
    transition             : all 0.3s ease-out;
  }

  .kit-scrollbar-autohide::-webkit-scrollbar-thumb {
    border          : 4px solid;
    border-color    : inherit;
    background-color: transparent;
  }

  .kit-scrollbar-autohide::-webkit-scrollbar-track {
    background-color: inherit;
  }

  .kit-scrollbar-autohide:hover {
    background-color: #c8c8c8;
    border-color    : #353a40;
  }

  .kit-scrollbar-small-autohide {
    -webkit-background-clip: text;
    background-color       : transparent;
    border-color           : transparent;
    transition             : all 0.3s ease-out;
  }

  .kit-scrollbar-small-autohide::-webkit-scrollbar {
    width : 4px;
    height: 4px;
  }

  .kit-scrollbar-small-autohide::-webkit-scrollbar-thumb {
    border          : 4px solid;
    border-color    : inherit;
    background-color: transparent;
  }

  .kit-scrollbar-small-autohide::-webkit-scrollbar-track {
    background-color: inherit;
  }

  .kit-scrollbar-small-autohide:hover {
    background-color: #c8c8c8;
    border-color    : #353a40;
  }

  .kit-scrollbar-small-autohide::-webkit-scrollbar-thumb {
    border      : 2px solid;
    border-color: inherit;
  }
}