$gapPadding : 15px;

.prompt {
  z-index: 1100;

  .modal-header {
    border        : none;
    padding       : $gapPadding $gapPadding 0px $gapPadding;
    display       : flex;
    flex-direction: column;
  }

  .modal-footer {
    border : none;
    padding: $gapPadding $gapPadding $gapPadding $gapPadding;
  }

  .modal-body {
    padding: $gapPadding $gapPadding $gapPadding $gapPadding;
  }

  .btn.btn-cancel {
    font-weight: 700;
    color      : darkgrey
  }
}

.prompt-transition-enter-done {
  .prompt {
    display: block;
    opacity: 1;

    .prompt-dialog {
      transform: translate(0, 0px);
    }
  }

  .fade.prompt-backdrop {
    opacity: .5;
    z-index: 1090;
  }
}

.prompt-transition-exit-active {
  .prompt {
    display: block;
    opacity: 0;

    .prompt-dialog {
      transform: translate(0, -50px);
    }
  }

  .fade.prompt-backdrop {
    opacity: .5;
    z-index: 1090;
  }
}