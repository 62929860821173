@import '../../../scss/theme';

.btn-plus-tooltip .feather {
  width: 20px;
  height: 20px;
}

.text-primary.btn-plus-tooltip {
  color: $blue;
}

.text-danger.btn-plus-tooltip {
  color: $red;
}

.membership-button-popover-container {
  position: relative;
  padding-left: 5px;
}

.popover-upgrade {
  position: absolute;
  top: -10px;
  left: 40px;
  width: 450px;
  padding: 15px;
  z-index: 1030;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.16);
  border-color: $blue;
  transform: translate(-100%, -100%);
}
