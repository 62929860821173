@import './scss/theme';

.Toastify__toast-container--top-right {
  top: 9em;
  right: 10px;
}

.Toastify__toast--default {
  background-color: $black;
  color: #fff;
  min-height: 96px;
}

.Toastify__close-button--default {
  color: #fff;
}

.Toastify__toast-body {
  margin: 0px;
}

.Toastify__toast {
  border-radius: 5px;
  overflow-y: auto;
  padding: 0;
}

.Toastify__toast .toast-close {
  position: absolute;
  right: 10px;
  top: 8px;
}

.Toastify__progress-bar--default {
  &.bg-light {
    background: #fff;
  }
}
