$pink: var(--success) !default;
$orange: var(--warning) !default;
$red: var(--danger) !default;
$blue: var(--info) !default;
$darkblue: var(--primary) !default;
$black: #343a3f !default;
$dark: #4d5256 !default;
$auth_background: var(--primary) !default;
$loader_auth: var(--primary) !default;
$gradient: linear-gradient(to right, $blue, $darkblue, $red) !default;
$primary_workinghours: var(--primary) !default;
$error_notification: #ec2544 !default;
$warning_notification: #ffbb00 !default;
$confirmation_notification: #78d6a4 !default;
$info_notification: #65d0ec !default;
