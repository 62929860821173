.canvas-wrapper {
  position: absolute;
  top     : 0;
  left    : 0;
  right   : 0;
  bottom  : 0;
  z-index : 1020;

  &.cursor-cell {
    canvas {
      cursor: cell;
    }
  }

  &.cursor-move {
    canvas {
      cursor: move;
    }
  }

  &.default-default {
    canvas {
      cursor: pointer;
    }
  }
}