.annotation-wrapper {
  position  : relative;
  width     : 100%;
  height    : 100%;
  background: #484d52;
  overflow  : hidden;

  .pdf-viewer-wrapper {
    position: absolute;
    top     : 0px;
    left    : 0;
    right   : 0;
    bottom  : 0;
    overflow: auto;
  }
}