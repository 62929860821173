.order-filter-wrapper {
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;

  .filter-header {
    padding: 15px 15px 0;
  }

  .filter-body {
    overflow-y: auto;

    .preset-form {
      padding: 0px 15px 20px;
    }
  }

  .date-input {
    .textbox-form-container {
      background: #fff;
    }
  }
}
