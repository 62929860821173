@import '../../../../scss/theme';

.tooltip-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1090;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.32), 0 0 1px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  font-size: 13px;

  .comment-container-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);

    &.comment-list-readonly {
      border-bottom: 1px solid transparent;
    }
  }

  .comment-wrapp {
    min-height: 70px;
  }

  .tooltip-tail {
    width: 16px;
    height: 8px;
    position: absolute;
    left: 0;
    transform: translateX(-50%);

    &.tooltip-top {
      bottom: -8px;

      &::after {
        border-left: 8px solid transparent;
        border-top: 8px solid;
        border-right: 8px solid transparent;
        position: relative;
        z-index: 1;
        content: '';
        display: block;
        border-top-color: $black;
        border-top-color: $black;
      }
    }

    &.tooltip-bottom {
      top: -8px;

      &::after {
        border-left: 8px solid transparent;
        border-bottom: 8px solid;
        border-right: 8px solid transparent;
        position: relative;
        z-index: 1;
        content: '';
        display: block;
        border-bottom-color: $black;
        border-bottom-color: $black;
      }
    }
  }

  .tooltip-content-wrapper {
    position: absolute;
    width: 320px;
    background-color: $black;
    border-radius: 10px;
    z-index: 10;

    &.tooltip-top {
      transform: translate(-50%, -100%);
    }

    &.tooltip-bottom {
      transform: translate(-50%, 0);
    }

    .btn-close {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 0;
      border: none;
      display: block;

      .close-icon {
        display: block;
        width: 20px;
        height: 20px;
        fill: $red;
        stroke: #fff;

        circle {
          stroke: $red;
        }
      }
    }
  }
}
