@import "../../scss/theme";

.progress-container {
  display       : flex;
  flex-direction: column;
  width         : 100%;
  margin-top    : 5px;
}

.progress-container>.progress {
  background-color: #fff;
  border-radius   : 5px;
  height          : 10px;
}

.progress-bar {
  border-radius: 5px;

  &.bg-gradient {
    background        : $gradient;
    // background-size: 400% 400%;
    // animation      : gradientBG 15s ease infinite;
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}