@import '../../../../scss/theme';

.header-annotation-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #484d52;
  z-index: 1100;
  font-size: 12px;
  overflow: hidden;
  height: 60px;
  color: #a9a9a9;

  hr {
    border-top: 1px solid #a9a9a9;
  }

  .title {
    color: #fff;
  }

  .badge {
    font-size: 10px;
    margin-left: 6px;

    .feather {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  ul.menu-annotation {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      position: relative;

      &:last-child {
        &::after {
          border: none;
        }
      }

      &:not(:last-child) {
        margin-right: 15px;
      }

      &::after {
        position: absolute;
        top: 6px;
        bottom: 6px;
        right: -7.5px;
        content: ' ';
        border-left: 1px solid #a9a9a9;
      }

      &.no-border {
        &:not(:last-child) {
          margin-right: 10px;
        }

        &::after {
          border-left: none;
        }
      }

      .btn-text-black {
        color: $black;

        &:hover {
          color: $black;
        }
      }

      .btn-text-white {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .btn-header-annotation {
    padding: 8px 10px;
    font-size: 12px;
    color: #a9a9a9;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: bold;

    &:active,
    &:focus {
      box-shadow: none;
    }

    &.active {
      background: $darkblue;
      color: #fff;
    }

    &:hover {
      background: $darkblue;
      color: #fff;
    }

    .badge-count {
      // position      : absolute;
      // top           : 0px;
      // right         : 0px;
      // transform     : translate(-70%, 32%);
      margin-left: 5px;
      border-radius: 5px;
      height: 16px;
      min-width: 16px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      line-height: 10px;
      font-size: 10px;
    }

    .icon-header-annotation {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    span {
      line-height: 12px;
    }
  }

  .btn-header-annotation-close {
    .icon-header-annotation {
      margin-right: 0px;
    }

    &:hover {
      background: inherit;
      color: #a9a9a9;
    }
  }
}
