.attachment-nav-wrapper {
  .nav-left {
    position : absolute;
    left     : 30px;
    top      : 50%;
    transform: translateY(-50%);
    z-index  : 1050;

    .btn {
      color                     : rgba(255, 255, 255, 0.75);
      transition-property       : transform, left, background-color, right;
      transition-duration       : 0.1s, 0.3s;
      transition-timing-function: ease-out;
      will-change               : transform;
      border-radius             : 50%;
      width                     : 48px;
      height                    : 48px;
      background-color          : rgba(0, 0, 0, 0.30000000000000004);

      .feather {
        width : 35px;
        height: 35px;
      }

      &:hover {
        color    : rgba(255, 255, 255, 0.90);
        transform: scale(1.33333333);
      }
    }
  }

  .nav-right {
    position : absolute;
    right    : 30px;
    top      : 50%;
    transform: translateY(-50%);
    z-index  : 1050;

    .btn {
      color                     : rgba(255, 255, 255, 0.75);
      transition-property       : transform, left, background-color, right;
      transition-duration       : 0.1s, 0.3s;
      transition-timing-function: ease-out;
      will-change               : transform;
      border-radius             : 50%;
      width                     : 48px;
      height                    : 48px;
      background-color          : rgba(0, 0, 0, 0.30000000000000004);

      .feather {
        width : 35px;
        height: 35px;
      }

      &:hover {
        color    : rgba(255, 255, 255, 0.90);
        transform: scale(1.33333333);
      }
    }
  }
}