@import '../../../../scss/theme';

.comment-editor-wrapper {
  cursor       : text;
  min-height   : 22px;
  padding      : 10px;
  width        : 100%;
  color        : rgba(255, 255, 255, 0.9);
  // border-top: 1px solid rgba(255, 255, 255, 0.16);

  .hint-bta {
    color: rgba(255, 255, 255, 0.32);
  }

  .mentioned-user {
    background-color: $darkblue;
    padding         : 1px 5px;
    border-radius   : 15px;
  }

  .DraftEditor-root {
    max-height: 72px;
    overflow-y: auto;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    top     : 0;
    right   : 0;
    left    : 0;
    bottom  : 0;
    z-index : 0;
  }

  .DraftEditorPlaceholder-hidden {
    display: none;
  }

  .public-DraftEditorPlaceholder-root,
  .public-DraftEditorPlaceholder-hasFocus {
    color: #9197a3;
  }

  .draftJsEmojiPlugin__emojiSuggestions__2ffcV {
    background-color: #484d52;
    border          : 1px solid #62666b;
    box-shadow      : none;
    border-radius   : 8px;
    min-height      : 40px;
    overflow-y      : auto;
    z-index         : 50;
    top             : 0 !important;
    bottom          : inherit !important;

    .draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
      background-color: $darkblue;
    }
  }

  .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    background-color: #484d52;
    border          : 1px solid #62666b;
    box-shadow      : none;
    border-radius   : 8px;
    min-height      : 40px;
    overflow-y      : auto;
    z-index         : 50;

    .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
      background-color: $darkblue;
    }
  }
}