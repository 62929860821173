.container-grey {
  background-color: #f1f1f1;
}

.container-border-grey {
  border: 1px solid rgba(0, 0, 0, 0.1)
}

.container-radius-10 {
  border-radius: 10px;
}

.container-radius-20 {
  border-radius: 20px;
}

.container-radius-30 {
  border-radius: 30px;
}