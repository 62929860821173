@import '../../../../scss/theme';

.color-palette-wrapper {
  position: relative;

  .palette-list {
    position: absolute;
    top: 0;
    transform: translate(0, 35px);
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.16);

    ul {
      li {
        .btn {
          box-shadow: none;
        }
      }
    }

    .color-blue {
      fill: $blue;
      stroke: $blue;

      &.active {
        stroke: $black;
      }
    }

    .color-red {
      fill: #ed1c24;
      stroke: #ed1c24;

      &.active {
        stroke: $black;
      }
    }

    .color-green {
      fill: #8dc63f;
      stroke: #8dc63f;

      &.active {
        stroke: $black;
      }
    }

    .color-yellow {
      fill: #f7941d;
      stroke: #f7941d;

      &.active {
        stroke: $black;
      }
    }

    .color-pink {
      fill: #f06eaa;
      stroke: #f06eaa;

      &.active {
        stroke: $black;
      }
    }

    .color-purple {
      fill: #8560a8;
      stroke: #8560a8;

      &.active {
        stroke: $black;
      }
    }
  }
}
