.wrapper-size-2 {
  width: 49%;
  display: inline-block;
  vertical-align: top;
  margin-right: 1%;

  &:last-child {
    margin-right: 0px;
    float: right;
  }
}

.wrapper-size-3 {
  width: 32.3%;
  display: inline-block;
  vertical-align: top;
  margin-right: 1%;

  &:last-child {
    margin-right: 0px;
    width: 33.3%;
  }
}

.wrapper-size-4 {
  width: 24%;
  display: inline-block;
  vertical-align: top;
  margin-right: 1%;

  &:last-child {
    margin-right: 0px;
    width: 25%;
  }
}
