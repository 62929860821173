@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform        : rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform        : rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform        : rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform        : rotate(360deg);
  }
}

.skeleton-pulse {
  display        : inline-block;
  height         : 100%;
  width          : 100%;
  background     : linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
  background-size: 400% 400%;
  animation      : pulse 1.2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}