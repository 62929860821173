@import "../../scss/theme";

.ladda-button {
  color           : #fff;
  background-color: #efefef;
  border-color    : #efefef;

  display            : inline-block;
  font-weight        : 700;
  text-align         : center;
  vertical-align     : middle;
  -webkit-user-select: none;
  user-select        : none;
  border             : 1px solid transparent;
  padding            : 0.375rem 0.75rem;
  font-size          : 0.75rem;
  line-height        : 1.5;
  border-radius      : 5px;
  transition         : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color           : #fff;
    background-color: #efefef;
    border-color    : #efefef;
  }
}