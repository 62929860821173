@import "../../../scss/theme";

.password-show-hide {
  border          : 1px solid #c3c3c3;
  border-radius   : 5px;
  display         : flex;
  flex-direction  : row;
  position        : relative;
  background-color: #fff;
  overflow        : hidden;

  input {
    border    : none;
    display   : flex;
    flex-grow : 1;
    flex-basis: 100%;
    width     : 100%;
    padding   : .375rem .75rem;

    &:focus {
      box-shadow: none;
      border    : none;
      outline   : none;
    }
  }

  .btn-icon {
    padding: 5px;
  }

  .input-validation-msg {
    position        : absolute;
    top             : 3px;
    right           : 30px;
    bottom          : 3px;
    background-color: $red;
    color           : #fff;
    border-radius   : 5px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    visibility      : hidden;
    opacity         : 0;
    transition      : opacity 350ms ease-in-out;

    span {
      display        : flex;
      justify-content: center;
      align-items    : center;
      padding        : 10px;
      font-size      : 13px;
    }

    &.show {
      opacity   : 1;
      visibility: visible;
    }
  }

  button {
    color  : #c3c3c3;
    display: flex;
  }
}