@import "../theme";

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@include font-face("Messina Sans", fonts/MessinaSans-Regular, 400, normal, eot woff2 woff);
@include font-face("Messina Sans", fonts/MessinaSans-Bold, bold, normal, eot woff2 woff);
@include font-face("MessinaSans", fonts/MessinaSans-Regular, 400, normal, eot woff2 woff);
@include font-face("MessinaSans-Bold", fonts/MessinaSans-Bold, bold, normal, eot woff2 woff);

h1,
h2,
h3,
h4,
h5 {
  color: $darkblue;
}

blockquote {
  border-left : 5px solid #f1f1f1;
  padding-left: 15px;
}

pre {
  display   : inline-block;
  font-size : 87.5%;
  color     : #212529;
  background: #f5f5f5;
  margin    : 0;
  padding   : 0 2px;
}

label {
  font-weight: 700;
  font-size  : 14px;
}

.text-black {
  color: #4d5256;
}

.text-gray {
  color: #c3c3c3;
}

.text-grey {
  color: #a9a9a9;
}

.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-24 {
  font-size: 24px;
}

.text-26 {
  font-size: 26px;
}

.text-40 {
  font-size: 40px;
}

.text-default {
  color: #a9a9a9;
}

.line-height-10 {
  line-height: 11px;
}

.line-height-11 {
  line-height: 11px;
}

.line-height-12 {
  line-height: 12px;
}

.line-height-14 {
  line-height: 14px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-100 {
  line-height: 100%;
}

.p-15 {
  padding: 15px;
}

.p-30 {
  padding: 30px;
}